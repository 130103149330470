import React from "react";
import { childrenNode } from "../../utils/type";

export const LocationFirstHeader = ({ children }: childrenNode) => {
  return (
    <li>
      <div className="flex">
        <div className="text-sm font-medium text-gray-500 hover:text-gray-700">
          {children}
        </div>
      </div>
    </li>
  );
};
