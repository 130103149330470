/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React from 'react';

interface Props {
  value: string | number;
  onChange: (...event: any[]) => void;
  filteredList: (string | number | any)[];
  reset?: () => void;
  placeholder?: string;
  isError?: boolean;
  error?: Error;
  savedIndex?: boolean;
}

export const ComboboxHeadlessInController = ({
  value,
  onChange,
  filteredList,
  reset,
  placeholder,
  isError,
  error,
  savedIndex = false,
}: Props) => {
  return (
    <Combobox as="div" value={value} onChange={onChange}>
      <div className="relative">
        <div className="flex w-full items-center justify-between gap-2">
          <div className="relative flex w-full items-center justify-between">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={value}
              onChange={onChange}
              placeholder={placeholder}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
          {reset && (
            <button
              type="button"
              className="button-gray h-9 px-2 focus:outline-none"
              onClick={reset}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          )}
        </div>
        <ComboboxOptions
          // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
          className={`absolute z-10 mt-1 max-h-60 ${reset ? 'w-[calc(100%-2.5rem)]' : 'w-full'} overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin focus:outline-none sm:text-sm`}
        >
          {isError ? (
            <div className="relative cursor-default select-none px-4 py-2 text-red-600">
              {error.message}
            </div>
          ) : filteredList?.length === 0 ? (
            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
              Nothing found
            </div>
          ) : (
            filteredList?.map((filteredValue, index) => (
              <ComboboxOption
                key={index}
                className={({ focus }) =>
                  `relative cursor-default select-none py-2 pl-3 pr-9 ${
                    focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  }`
                }
                value={savedIndex ? index + 1 : filteredValue}
              >
                {({ selected, focus }) => (
                  <>
                    <span
                      className={`break-all ${selected && 'font-semibold'}`}
                    >
                      {filteredValue}
                    </span>
                    {selected && (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                          focus ? 'text-white' : 'text-indigo-600'
                        }`}
                      >
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))
          )}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};
