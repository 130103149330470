import React from 'react';

type Props = {
  state: {
    email: string;
    name: string | null;
  }[];
  setState: React.Dispatch<
    React.SetStateAction<
      {
        email: string;
        name: string | null;
      }[]
    >
  >;
  isRunning?: boolean;
};

export const EmailList = ({ state, setState, isRunning = true }: Props) => {
  const deleteTagItem = (indexToDelete: number) => {
    setState(state?.filter((_, index) => index !== indexToDelete));
  };

  return (
    <div className="mt-4">
      {state?.map((item, index) => (
        <span
          key={item.email}
          className="m-1 inline-flex items-center justify-between gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
        >
          {item?.name === null ? item?.email : `${item?.email}(${item?.name})`}
          {isRunning && (
            <button
              type="button"
              className="group relative -mr-1 size-3.5 rounded-sm hover:bg-gray-500/20"
              onClick={() => deleteTagItem(index)}
            >
              <span className="sr-only">Remove</span>
              <svg
                viewBox="0 0 14 14"
                className="size-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
              >
                <path d="M4 4l6 6m0-6l-6 6" />
              </svg>
              <span className="absolute -inset-1" />
            </button>
          )}
        </span>
      ))}
    </div>
  );
};
