import React, { forwardRef } from "react";

import { childrenNode } from "../../utils/type";

export const TableBodyContainer = forwardRef<
  HTMLTableSectionElement,
  childrenNode
>(({ children }, ref) => {
  return (
    <tbody ref={ref} id="list" className="divide-y divide-gray-200">
      {children}
    </tbody>
  );
});
