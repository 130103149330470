import React from 'react';
import { useLinkCopy } from '../../../hooks/useLinkCopy';
import { CopyLinkAndTooltip } from '../Tooltip/CopyLinkAndTooltip';
import { SuccessCheckAndTooltip } from '../Tooltip/SuccessCheckAndTooltip';

type Props = {
  url: string;
};
export const LinkButton = ({ url }: Props) => {
  const { isCopied, linkCopy } = useLinkCopy();

  return (
    <button
      type="button"
      className="group relative"
      onClick={() => linkCopy(url)}
    >
      <CopyLinkAndTooltip isCopied={!isCopied} />
      <SuccessCheckAndTooltip isCopied={isCopied} />
    </button>
  );
};
