import React from 'react';

type Props = {
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultChecked: boolean;
  onClick?: () => void;
  title: string;
  disabled?: boolean;
};

export const InputCheckBox = ({
  id,
  name,
  onChange,
  defaultChecked,
  onClick,
  title,
  disabled = false,
}: Props) => {
  return (
    <div key={id} className="flex items-center">
      <input
        id={id}
        name={name}
        type="radio"
        onChange={onChange}
        defaultChecked={defaultChecked}
        onClick={onClick}
        value={id}
        className="h-4 w-4 border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600 align-top top-1 rounded-[50%] transition-all duration-200 ease-linear appearance-none checked:border-[6px] checked:border-solid checked:border-indigo-600"
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className="ml-3 block text-sm font-medium leading-6 text-gray-900 cursor-pointer"
      >
        {title}
      </label>
    </div>
  );
};
