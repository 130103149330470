import React from "react";

import { childrenNode } from "../../utils/type";

export const TableHeaderContainer = ({ children }: childrenNode) => {
  return (
    <thead>
      <tr>
        {children}
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  );
};
