import React from 'react';

export const GroupContentSkeleton = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className="border border-gray-200 rounded-lg shadow-sm relative animate-pulse"
        >
          <div className="bg-gray-400/10 rounded-t-md border-b text-gray-800 p-3">
            <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
            <div className="w-6 h-6 bg-gray-300 rounded-full ml-auto"></div>
          </div>
          <div className="p-5">
            <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
            <div className="flex justify-between border-t pt-2 mb-1">
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            </div>
            <div className="flex justify-between">
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
