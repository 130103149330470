import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

interface ListItem {
  [key: string]: any;
}

interface Props {
  state: string | ListItem | any;
  setState: React.Dispatch<React.SetStateAction<string | ListItem | any>>;
  list: (ListItem | any)[];
  wordInButton?: string;
  saveWord?: string;
  wordInOptions?: string;
  disabled?: boolean;
}

export const ProjectTitleListUI = ({
  state,
  setState,
  list,
  wordInButton,
  saveWord,
  wordInOptions,
  disabled,
}: Props) => {
  const navigate = useNavigate();
  return (
    <Listbox value={state} onChange={setState} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative flex grow">
            <ListboxButton className="text-sm relative flex grow cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 hover:cursor-pointer focus:outline-none sm:leading-6 hover:bg-gray-50">
              <span className="block truncate">
                {typeof state === 'string'
                  ? state
                  : state?.[wordInButton ?? '']}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-12 max-h-96 w-full overflow-y-auto scrollbar-thin rounded-md bg-white py-1 text-sm shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                {list?.length === 0 ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Not Founded
                  </div>
                ) : (
                  list?.map((value, index) => (
                    <ListboxOption
                      key={index}
                      className={({ focus }) =>
                        `relative cursor-default select-none py-2 pl-4 pr-9 ${
                          focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        }`
                      }
                      value={
                        saveWord && typeof value === 'object' && value !== null
                          ? value?.[saveWord]
                          : value
                      }
                      onClick={() =>
                        navigate(
                          `/ide/projects/${value.idx}/dashboard/overview`,
                        )
                      }
                    >
                      {({ focus }) => {
                        const isSelected =
                          typeof state === 'string'
                            ? state === value.name
                            : state[wordInButton ?? ''] ===
                              value[wordInOptions];
                        return (
                          <>
                            <span
                              className={`block break-all ${
                                isSelected ? 'font-semibold' : 'font-normal'
                              }`}
                            >
                              {typeof value === 'string' ||
                              typeof value === 'number'
                                ? value
                                : value?.[wordInOptions]}
                            </span>
                            {isSelected ? (
                              <span
                                className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                  focus ? 'text-white' : 'text-indigo-600'
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
