import React, { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

type Props = {
  page: number;
  item: number | string;
  children: ReactNode;
  isDashboard?: boolean;
};

export const PaginationNumberButton = ({
  page,
  item,
  children,
  isDashboard = true,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {isDashboard ? (
        <Link
          to={{ pathname: location.pathname, search: `page=${item}` }}
          aria-current={page === item ? "page" : null}
          className={`relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 ${
            page === item
              ? "bg-congress-blue text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              : "text-gray-900 bg-white focus:outline-offset-0 hover:bg-gray-50 ring-1 ring-inset ring-gray-300"
          }`}
        >
          {children}
        </Link>
      ) : (
        <button
          aria-current={page === item ? "page" : null}
          className={`relative z-10 inline-flex items-center mx-4 px-4 py-2 text-sm font-semibold focus:z-20 ${
            page === item
              ? "bg-congress-blue text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              : "bg-white text-gray-900 focus:outline-offset-0 hover:bg-gray-50 ring-1 ring-inset ring-gray-300"
          }`}
          onClick={() =>
            navigate({ pathname: location.pathname, search: `page=${item}` })
          }
        >
          {children}
        </button>
      )}
    </>
  );
};
