import React from 'react';

type Props = {
  size?: 'small' | 'medium' | 'large';
};
const sizeAttributes = {
  small: {
    width: '4',
    height: '24',
    viewBox: '0 0 4 24',
    rectWidth: '3',
    rectHeight: '24',
    transform: 'translate(0.333374)',
  },
  medium: {
    width: '5',
    height: '46',
    viewBox: '0 0 5 46',
    rectWidth: '5',
    rectHeight: '46',
    transform: '',
  },
  large: {
    width: '6',
    height: '63',
    viewBox: '0 0 6 63',
    rectWidth: '6',
    rectHeight: '63',
    transform: '',
  },
};

export const StartAndFinishMark = ({ size = 'small' }: Props) => {
  const attributes = sizeAttributes[size];
  return (
    <svg
      width={attributes.width}
      height={attributes.height}
      viewBox={attributes.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={attributes.rectWidth}
        height={attributes.rectHeight}
        transform={attributes.transform}
        fill="#043E8D"
      />
    </svg>
  );
};
