import { DetailArrow } from '@autosquare/common';
import React from 'react';

export const SpeedTestResultTableSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 pt-8">
      {Array.from({ length: 5 }, (_, i) => (
        <div className="flex w-full flex-col gap-3.5" key={i}>
          <div className="flex w-full items-center justify-between">
            <div className="h-5 w-16 animate-pulse rounded bg-gray-200" />
            <div className="flex gap-1.5">
              <span className="text-center text-xs font-semibold leading-tight text-gray-900">
                Detail
              </span>
              <DetailArrow />
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="text-sm font-semibold leading-tight text-gray-900">
                <tr className="border-b border-solid border-gray-200 bg-gray-50">
                  <th className="w-24 px-3 py-2">
                    <div className="h-5 w-16 animate-pulse rounded bg-gray-200" />
                  </th>
                  <th className="w-24 px-3 py-2">
                    <div className="h-5 w-16 animate-pulse rounded bg-gray-200" />
                  </th>
                  <th className="w-full px-3 py-2">
                    <div className="h-5 w-16 animate-pulse rounded bg-gray-200" />
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm font-normal leading-tight text-gray-900">
                <tr className="border-b border-solid border-gray-400">
                  <td className="px-3 py-2">
                    <div className="h-9 w-16 animate-pulse rounded bg-gray-200" />
                  </td>
                  <td className="px-3 py-2">
                    <div className="h-9 w-16 animate-pulse rounded bg-gray-200" />
                  </td>
                  <td className="px-3 py-2">
                    <div className="h-9 w-16 animate-pulse rounded bg-gray-200" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};
