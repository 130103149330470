import { useEffect, useRef, useState } from 'react';

export const useScrollEvent = (items: string[]) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [userIsScrolling, setUserIsScrolling] = useState(false);

  const handleScroll = (e: Event) => {
    const target = e.target as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const isBottom = Math.ceil(scrollTop) + clientHeight >= scrollHeight;

    setUserIsScrolling(!isBottom);
  };

  useEffect(() => {
    const current = scrollRef.current;

    if (current) {
      current.addEventListener('scroll', handleScroll);

      return () => {
        current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [items]);

  useEffect(() => {
    const current = scrollRef.current;

    if (!userIsScrolling && current) {
      current.scrollTop = current.scrollHeight;
    }
  }, [items, userIsScrolling]);

  return { scrollRef };
};
