import React from "react";

type Props = {
  subTitle: string;
  description?: string | null;
};

export const SubHeaderTitle = ({ subTitle, description }: Props) => {
  return (
    <>
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        {subTitle}
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">{description}</p>
    </>
  );
};
