import React from "react";

export const TestResultDetailSkeleton = () => {
  const skeletonList = ["Summary", "Information", "Execution Details"];

  return (
    <>
      {skeletonList.map((skeletonTitle) => (
        <div key={skeletonTitle}>
          <div className="mt-6 border-b border-gray-200 pb-3">
            <h2 className="flex items-center text-base font-semibold leading-6 text-gray-900">
              {skeletonTitle}
            </h2>
          </div>
          <div className="p-4">
            <div className="w-full h-9 rounded-lg bg-gray-200 dark:bg-gray-700 animate-pulse" />
          </div>
        </div>
      ))}
    </>
  );
};
