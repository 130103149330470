export type PlatFormTypeList = {
  idx: number;
  name: PlatFormTypeName;
  serverName: PlatFormTypeServerName;
};

export enum PlatFormTypeName {
  Default = '-',
  MobileApp = 'Mobile App',
  MobileWeb = 'Mobile Web',
}

export enum PlatFormTypeServerName {
  Default = '-',
  MobileApp = 'mobile_app',
  MobileWeb = 'mobile_web',
}

export const platformTypeList: PlatFormTypeList[] = [
  {
    idx: 0,
    name: PlatFormTypeName.Default,
    serverName: PlatFormTypeServerName.Default,
  },
  {
    idx: 1,
    name: PlatFormTypeName.MobileApp,
    serverName: PlatFormTypeServerName.MobileApp,
  },
  {
    idx: 2,
    name: PlatFormTypeName.MobileWeb,
    serverName: PlatFormTypeServerName.MobileWeb,
  },
];

export const findPlatformTypeNameFromServerName = (
  serverName: PlatFormTypeServerName,
) =>
  platformTypeList.find(
    (platformType) => platformType.serverName === serverName,
  )?.name;
