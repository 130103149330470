import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const PaginationDotDotDot = ({ children }: Props) => {
  return (
    <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
      {children}
    </span>
  );
};
