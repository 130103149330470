import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  padding?: "sm" | "md" | "lg";
};

export const TableData = ({ children, padding = "md" }: Props) => {
  const paddingClasses = {
    sm: "px-3 py-3.5",
    md: "px-3 py-4",
    lg: "px-4 py-5",
  };

  return (
    <td className={`${paddingClasses[padding]} text-sm text-gray-500`}>
      {children}
    </td>
  );
};
