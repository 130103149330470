import React from 'react';

export const SpeedTestResultDetailsSkeleton = () => {
  return (
    <>
      <div className="mt-3">
        <p className="mb-3 h-5 w-32 rounded-md border-y border-gray-200 bg-gray-200 py-3" />
        <hr />
        <table className="min-w-full">
          <tbody>
            {Array.from({ length: 4 }).map((_, i) => (
              <tr key={i}>
                <td className="flex items-center justify-start gap-x-8 px-6 py-3">
                  <span className="block h-10 w-40 rounded-md bg-gray-200"></span>
                  <span className="block h-10 w-52 rounded-md bg-gray-200"></span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3">
        <button
          type="button"
          className="mb-2 h-5 w-32 rounded-md bg-gray-200 py-3"
        ></button>
        <hr />
        <div className="flex items-center justify-start px-6 py-5">
          <div className="h-7 w-32 animate-pulse rounded-md bg-gray-200"></div>
        </div>
      </div>
      <div className="mt-3">
        <p className="h-5 w-72 animate-pulse rounded-md py-3"></p>
        <div className="h-5 w-64 rounded-md bg-gray-200"></div>
      </div>
      <div className="mt-3 grid grid-cols-2 gap-x-8">
        <table className="min-w-1/2">
          <thead>
            <tr className="border-b border-gray-200">
              {Array.from({ length: 4 }).map((_, i) => (
                <th
                  key={i}
                  className="animate-pulse px-6 py-3 text-left text-sm font-medium text-gray-900"
                >
                  <span className="block h-4 w-20 rounded-md bg-gray-200"></span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 3 }).map((_, i) => (
              <tr key={i} className="border-b border-gray-300">
                {Array.from({ length: 4 }).map((_, i) => (
                  <td key={i} className="animate-pulse px-6 py-4">
                    <span className="block h-5 rounded-md bg-gray-200"></span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <table className="min-w-1/2">
          <thead className="flex space-x-8 border-b border-gray-200 pb-2">
            {Array.from({ length: 2 }).map((_, i) => (
              <tr
                key={i}
                className="mt-2 animate-pulse rounded-md border-b-2 bg-gray-200"
              >
                <td className="h-5 w-16"></td>
              </tr>
            ))}
          </thead>
        </table>
      </div>
    </>
  );
};
