import React from 'react';

export const CardListSkeleton = () => {
  return (
    <div className="size-full animate-pulse rounded border border-gray-200 p-4 shadow md:p-6 dark:border-gray-700">
      {Array.from({ length: 10 }, (_, i) => i).map((x) => (
        <div
          className="mb-4 h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"
          key={x}
        ></div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};
