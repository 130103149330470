import React from 'react';

interface TooltipProps {
  children: React.ReactNode;
  positionClass: string;
  customStyle?: React.CSSProperties;
}

// positionClass에는 left- bottom- after:left-[%] 로 사용 필요
export const TooltipTop = ({
  children,
  positionClass,
  customStyle,
}: TooltipProps) => {
  return (
    <div className={`tooltip-top ${positionClass}`} style={customStyle}>
      {children}
    </div>
  );
};
