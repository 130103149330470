import clsx from 'clsx';
import React from 'react';

type Props = {
  number?: number;
  height?: string;
  even?: boolean;
  margin?: string;
  padding?: string;
};

export const TableSkeletonDefault = ({
  number = 1,
  height = 'h-6',
  even = false,
  margin = 'my-8',
  padding = 'py-4',
}: Props) => {
  return (
    <ul className={clsx('max-w-full animate-pulse', margin)}>
      {Array.from(Array(number), (_, idx) => (
        <li
          key={idx}
          className={clsx(
            'border-b border-solid border-gray-200',
            padding,
            even && 'even:bg-gray-50',
          )}
        >
          <div
            className={clsx(
              'rounded-full bg-gray-200 dark:bg-gray-700',
              height,
            )}
          />
        </li>
      ))}
      <span className="sr-only">Loading...</span>
    </ul>
  );
};
