import React from "react";
import { Switch } from "@headlessui/react";

type Props = {
  isEnabled: boolean | null;
  setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ToggledSwitch = ({ isEnabled, setIsEnabled }: Props) => {
  return typeof isEnabled === "boolean" ? (
    <Switch
      checked={typeof isEnabled === "boolean" && isEnabled}
      onChange={setIsEnabled}
      className={`${
        typeof isEnabled === "boolean" && isEnabled
          ? "bg-congress-blue"
          : "bg-gray-200"
      } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          typeof isEnabled === "boolean" && isEnabled
            ? "translate-x-5"
            : "translate-x-0"
        } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  ) : (
    <div className="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent">
      <span className="translate-x-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0" />
    </div>
  );
};
