import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  colSpan: number;
};

export const TableBodyBasicNoData = ({ colSpan, children }: Props) => {
  return (
    <tr className="even:bg-gray-50">
      <td
        className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-center"
        colSpan={colSpan}
      >
        {children}
      </td>
    </tr>
  );
};
