import {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  ChangeEvent,
} from 'react';

export const useNumberInput = <T extends number>(
  initialForm: T,
): [
  T,
  (e: ChangeEvent<HTMLInputElement>) => void,
  Dispatch<SetStateAction<T>>,
] => {
  const [form, setForm] = useState(initialForm);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.valueAsNumber;

      setForm(isNaN(value) ? initialForm : (value as T));
    },
    [initialForm],
  );

  return [form, onChange, setForm];
};
