import React from "react";

type Props = {
  page: number;
  total: number;
  numPages: number;
};

export const ShowingResult = ({ page, total, numPages }: Props) => {
  return (
    <div>
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{page} </span> to
        <span className="font-medium"> {numPages} </span> of
        <span className="font-medium"> {total}</span> results
      </p>
    </div>
  );
};
