import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

export const TableBodyGray = ({ children, className }: Props) => {
  return (
    <td className={`px-3 py-4 text-sm text-gray-500 ${className}`}>
      {children}
    </td>
  );
};
