import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

interface ListItem {
  [key: string]: any;
}

type Props = {
  state: string | ListItem | any;
  setState: React.Dispatch<React.SetStateAction<string | ListItem | any>>;
  list: (ListItem | any)[];
  wordInButton?: string;
  savedValue?: string;
  wordInOptions?: string;
  disabled?: boolean;
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
};

export const ListBoxHeadlessUI = ({
  state,
  setState,
  list,
  wordInButton,
  savedValue,
  wordInOptions,
  disabled,
  onClickButton,
}: Props) => {
  return (
    <Listbox value={state} onChange={setState} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative">
            <ListboxButton
              className={clsx(
                'relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                disabled ? 'bg-gray-100' : 'bg-white',
              )}
              onClick={onClickButton}
            >
              <span className="block truncate">
                {typeof state === 'string' || typeof state === 'number'
                  ? state
                  : state?.[wordInButton ?? '']}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                {list?.length === 0 ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Not Founded
                  </div>
                ) : (
                  list?.map((value, index) => (
                    <ListboxOption
                      key={index}
                      className={({ focus }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-9 ${
                          focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        }`
                      }
                      value={
                        savedValue &&
                        typeof value === 'object' &&
                        value !== null
                          ? value?.[savedValue]
                          : value
                      }
                    >
                      {({ selected, focus }) => (
                        <>
                          <span
                            className={`block break-all ${
                              selected ? 'font-semibold' : 'font-normal'
                            }`}
                          >
                            {typeof value === 'string' ||
                            typeof value === 'number'
                              ? value
                              : value?.[wordInOptions]}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                focus ? 'text-white' : 'text-indigo-600'
                              }`}
                            >
                              <CheckIcon
                                className="size-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
