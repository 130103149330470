import React, { ReactNode } from "react";

type Props = {
  isInvalid: boolean;
  children: ReactNode;
};

export const TableRowBackgroundGray = ({ isInvalid, children }: Props) => {
  return (
    <tr
      className={`border-y-[1px] border-y-solid border-y-gray-200 ${
        isInvalid && "bg-gray-50"
      }`}
    >
      {children}
    </tr>
  );
};
