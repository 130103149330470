import React from 'react';

export const NoDevice = () => {
  return (
    <div
      className="mt-3 flex w-full items-start justify-center rounded-md border border-solid border-gray-200 bg-gray-50 
        p-2 text-sm"
    >
      연결된 디바이스가 없습니다.
    </div>
  );
};
