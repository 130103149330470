import { ChangeEvent, useState } from 'react';

export const useOnlyNumberInput = (initialValue: string = '') => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const newValue = inputValue.replaceAll(/\D/g, '');
    setValue(newValue);
  };

  const numberValue = value === '' ? null : parseInt(value, 10);

  return { value, setValue, handleChange, numberValue };
};
