import React, { ChangeEventHandler } from 'react';

type Props = {
  id: string;
  labelName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
};

export const CheckBoxInput = ({
  id,
  labelName,
  onChange,
  disabled = false,
  checked,
  defaultChecked,
}: Props) => {
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input
          id={id}
          name={id}
          type="checkbox"
          className="size-4 rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600"
          onChange={onChange}
          disabled={disabled}
          checked={checked}
          defaultChecked={defaultChecked}
        />
      </div>
      {labelName && (
        <div className="text-sm leading-6">
          <label htmlFor={id} className="font-medium text-gray-900">
            {labelName}
          </label>
        </div>
      )}
    </div>
  );
};
