import React from "react";

import { childrenNode } from "./../../utils/type";

export const MiddleAlign = ({ children }: childrenNode) => {
  return (
    <main className="flex flex-col justify-center items-center m-auto w-[25rem] min-h-screen text-[1rem]">
      {children}
    </main>
  );
};
