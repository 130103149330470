import React from 'react';

export const SidebarSkeleton = () => {
  return (
    <div role="status" className={`max-w-full animate-pulse my-6`}>
      {Array.from({ length: 1 }, (_, i) => (
        <div
          key={i}
          className={`bg-gray-300 rounded-md h-6 w-2/3 my-3 mx-6 mb-10`}
        />
      ))}
      <div className="sr-only mb-10">Loading</div>
      {Array.from({ length: 5 }, (_, i) => (
        <div key={i} className={`bg-gray-300 rounded-md h-6 w-3/5 my-3 mx-6`} />
      ))}
    </div>
  );
};
