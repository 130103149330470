import React, { Fragment } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from '@headlessui/react';
import { Button } from '../Button/Button';
import { ErrorMark } from '../SVG/ErrorMark';
import { useNavigate } from 'react-router-dom';
import SuccessMark from '../SVG/SuccessMark';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
};

export const ResultDialogHeadlessUI = ({ isOpen, setIsOpen, title }: Props) => {
  const dialogMessage = {
    success: `비밀번호 변경이 완료되었습니다. \n로그인 화면으로 이동합니다.`,
    error: `비밀번호 변경에 실패했습니다. \n처음부터 다시 시도해 주세요.`,
  };
  const navigate = useNavigate();
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <DialogPanel className="mb-40 w-full max-w-lg rounded-lg bg-white p-6 shadow-lg drop-shadow-md">
            <div className="mb-5 flex items-start justify-start gap-x-3">
              {title === 'Error' ? <ErrorMark /> : <SuccessMark />}
              <div className="flex flex-col gap-y-3 py-1">
                <DialogTitle className="font-semibold">{title}</DialogTitle>
                <p className="whitespace-pre-line text-sm text-gray-500">
                  {title === 'Error'
                    ? dialogMessage.error
                    : dialogMessage.success}
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() => navigate('/login')}
                variant={title === 'Error' ? 'caution' : 'primary'}
                className="w-24"
              >
                OK
              </Button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
};
