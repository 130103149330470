import React from "react";

export const Help = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM13.628 8.083C12.738 7.306 11.262 7.306 10.373 8.083C10.2233 8.21402 10.0277 8.28019 9.82915 8.26697C9.63064 8.25375 9.44552 8.16221 9.3145 8.0125C9.18348 7.86279 9.11731 7.66716 9.13053 7.46865C9.14375 7.27014 9.23529 7.08502 9.385 6.954C10.839 5.682 13.161 5.682 14.615 6.954C16.128 8.278 16.128 10.472 14.615 11.796C14.362 12.0166 14.0805 12.2023 13.778 12.348C13.102 12.676 12.75 13.122 12.75 13.5V14.25C12.75 14.4489 12.671 14.6397 12.5303 14.7803C12.3897 14.921 12.1989 15 12 15C11.8011 15 11.6103 14.921 11.4697 14.7803C11.329 14.6397 11.25 14.4489 11.25 14.25V13.5C11.25 12.221 12.31 11.393 13.125 10.998C13.307 10.91 13.476 10.799 13.628 10.667C14.458 9.94 14.458 8.81 13.628 8.083ZM12 18C12.1989 18 12.3897 17.921 12.5303 17.7803C12.671 17.6397 12.75 17.4489 12.75 17.25C12.75 17.0511 12.671 16.8603 12.5303 16.7197C12.3897 16.579 12.1989 16.5 12 16.5C11.8011 16.5 11.6103 16.579 11.4697 16.7197C11.329 16.8603 11.25 17.0511 11.25 17.25C11.25 17.4489 11.329 17.6397 11.4697 17.7803C11.6103 17.921 11.8011 18 12 18Z"
        fill="currentColor"
      />
    </svg>
  );
};
