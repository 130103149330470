import React from "react";

import { childrenNode } from "../../utils/type";

export const LocationHeader = ({ children }: childrenNode) => {
  return (
    <div>
      <div>
        <div className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            {children}
          </ol>
        </div>
      </div>
    </div>
  );
};
