import React from 'react';

export const Time = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_21392_3890)">
        <path
          d="M6 2.66667V6H8.5M11 6C11 6.65661 10.8707 7.30679 10.6194 7.91342C10.3681 8.52005 9.99983 9.07124 9.53553 9.53553C9.07124 9.99983 8.52005 10.3681 7.91342 10.6194C7.30679 10.8707 6.65661 11 6 11C5.34339 11 4.69321 10.8707 4.08658 10.6194C3.47996 10.3681 2.92876 9.99983 2.46447 9.53553C2.00017 9.07124 1.63188 8.52005 1.3806 7.91342C1.12933 7.30679 1 6.65661 1 6C1 4.67392 1.52678 3.40215 2.46447 2.46447C3.40215 1.52678 4.67392 1 6 1C7.32608 1 8.59785 1.52678 9.53553 2.46447C10.4732 3.40215 11 4.67392 11 6Z"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_21392_3890">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
