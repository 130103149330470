import React from "react";

import { childrenNode } from "../../utils/type";

export const HeaderContainer = ({ children }: childrenNode) => {
  return (
    <div className="mt-2 md:flex md:items-center md:justify-between">
      {children}
    </div>
  );
};
