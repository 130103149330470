import React, { ReactNode } from "react";

import { ChevronRightIcon } from "@heroicons/react/20/solid";

type Props = {
  children: ReactNode;
};

export const LocationHeaderList = ({ children }: Props) => {
  return (
    <li>
      <div className="flex">
        <ChevronRightIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />

        <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
          {children}
        </div>
      </div>
    </li>
  );
};
