import { Dispatch, SetStateAction } from "react";

export const useShowErrorMessage = (
  message: string,
  setMessage: Dispatch<SetStateAction<string>>,
  time: number
) => {
  setMessage(message);
  setTimeout(() => {
    setMessage("");
  }, time);
};
