import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isFirstPaddingZero?: boolean;
  isBreakAll?: boolean;
  withPadding?: boolean;
};

export const SpeedTableBodyBasic = ({
  children,
  isFirstPaddingZero = false,
  isBreakAll = false,
  withPadding = true,
}: Props) => {
  return (
    <>
      <td
        className={clsx('whitespace-pre-wrap px-3 pl-4 text-sm text-gray-900', {
          'first:px-0': isFirstPaddingZero,
          'break-all': isBreakAll,
          'py-4': withPadding,
        })}
      >
        {children}
      </td>
    </>
  );
};
