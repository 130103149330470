import React from "react";

export const UITest = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 6.75137C11.9999 5.93137 12.1918 5.12272 12.5604 4.39022C12.929 3.65773 13.464 3.02175 14.1226 2.53326C14.7812 2.04476 15.5451 1.71734 16.3531 1.57722C17.161 1.4371 17.9905 1.48818 18.7752 1.72637C18.8992 1.76394 19.0113 1.833 19.1007 1.92682C19.1901 2.02064 19.2537 2.13602 19.2852 2.2617C19.3167 2.38739 19.3151 2.51911 19.2806 2.644C19.2461 2.7689 19.1798 2.88272 19.0882 2.97437L15.7682 6.29337C15.8312 6.76837 16.0442 7.22737 16.4092 7.59237C16.7742 7.95737 17.2332 8.17037 17.7092 8.23237L21.0272 4.91337C21.1188 4.82176 21.2326 4.75546 21.3575 4.72093C21.4824 4.6864 21.6141 4.68482 21.7398 4.71635C21.8655 4.74787 21.9809 4.81142 22.0747 4.9008C22.1685 4.99019 22.2376 5.10235 22.2752 5.22637C22.5244 6.04722 22.5686 6.91664 22.4041 7.75857C22.2396 8.60049 21.8712 9.38926 21.3313 10.0559C20.7913 10.7225 20.0963 11.2467 19.3069 11.5825C18.5175 11.9184 17.6579 12.0556 16.8032 11.9824C15.7852 11.8964 14.9332 12.0824 14.4942 12.6164L7.34416 21.3014C7.0514 21.6551 6.68799 21.9439 6.2772 22.1491C5.86642 22.3543 5.4173 22.4714 4.95862 22.493C4.49994 22.5147 4.0418 22.4403 3.61353 22.2746C3.18527 22.1089 2.79632 21.8557 2.47158 21.531C2.14685 21.2063 1.8935 20.8174 1.72774 20.3892C1.56198 19.961 1.48748 19.5029 1.50899 19.0442C1.53051 18.5855 1.64758 18.1363 1.85269 17.7255C2.05781 17.3147 2.34646 16.9512 2.70016 16.6584L11.3842 9.50737C11.9172 9.06737 12.1042 8.21637 12.0182 7.19837C12.0059 7.04969 11.9999 6.90056 12.0002 6.75137ZM4.11716 19.1264C4.11716 18.9275 4.19618 18.7367 4.33683 18.596C4.47748 18.4554 4.66825 18.3764 4.86716 18.3764H4.87516C5.07407 18.3764 5.26484 18.4554 5.40549 18.596C5.54614 18.7367 5.62516 18.9275 5.62516 19.1264V19.1344C5.62516 19.3333 5.54614 19.524 5.40549 19.6647C5.26484 19.8054 5.07407 19.8844 4.87516 19.8844H4.86716C4.66825 19.8844 4.47748 19.8054 4.33683 19.6647C4.19618 19.524 4.11716 19.3333 4.11716 19.1344V19.1264Z"
        fill="currentColor"
      />
      <path
        d="M10.0762 8.64073L7.87522 6.44073V4.87473C7.87521 4.74525 7.84168 4.61797 7.77789 4.50529C7.7141 4.39261 7.62223 4.29837 7.51122 4.23173L3.76121 1.98173C3.61779 1.89575 3.44974 1.86017 3.28378 1.88064C3.11781 1.90112 2.96345 1.97647 2.84522 2.09473L2.09522 2.84473C1.97696 2.96296 1.90161 3.11732 1.88113 3.28329C1.86066 3.44925 1.89624 3.6173 1.98222 3.76073L4.23221 7.51073C4.29885 7.62174 4.3931 7.71362 4.50578 7.7774C4.61846 7.84119 4.74573 7.87472 4.87522 7.87473H6.43922L8.50122 9.93673L10.0762 8.63973V8.64073Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5562 17.3296L16.7392 21.5116C17.0526 21.825 17.4246 22.0736 17.8341 22.2432C18.2436 22.4128 18.6824 22.5001 19.1257 22.5001C19.5689 22.5001 20.0077 22.4128 20.4172 22.2432C20.8267 22.0736 21.1988 21.825 21.5122 21.5116C21.8256 21.1982 22.0742 20.8261 22.2438 20.4167C22.4134 20.0072 22.5007 19.5683 22.5007 19.1251C22.5007 18.6819 22.4134 18.243 22.2438 17.8335C22.0742 17.4241 21.8256 17.052 21.5122 16.7386L18.2062 13.4336C17.6994 13.5054 17.1861 13.5199 16.6762 13.4766C16.2822 13.4426 15.9942 13.4706 15.8092 13.5186C15.7506 13.531 15.6943 13.5522 15.6422 13.5816L12.5562 17.3296ZM15.9702 15.9696C16.1108 15.8291 16.3014 15.7503 16.5002 15.7503C16.6989 15.7503 16.8895 15.8291 17.0302 15.9696L18.9052 17.8456C18.9788 17.9143 19.0379 17.9971 19.0789 18.0891C19.1199 18.1811 19.142 18.2804 19.1437 18.3811C19.1455 18.4818 19.127 18.5818 19.0893 18.6752C19.0516 18.7686 18.9954 18.8534 18.9242 18.9246C18.853 18.9959 18.7681 19.052 18.6747 19.0897C18.5814 19.1274 18.4813 19.146 18.3806 19.1442C18.2799 19.1424 18.1806 19.1204 18.0886 19.0794C17.9966 19.0384 17.9138 18.9793 17.8452 18.9056L15.9702 17.0306C15.8297 16.89 15.7508 16.6993 15.7508 16.5006C15.7508 16.3018 15.8297 16.1112 15.9702 15.9706V15.9696Z"
        fill="currentColor"
      />
    </svg>
  );
};
