import React from "react";

type Props = {
  number?: number;
};

export const SelectMobileAndBrowserSkeleton = ({ number = 1 }: Props) => {
  return (
    <>
      {Array.from({ length: number }, (_, index) => (
        <div className="mt-3 grid gap-6 grid-cols-4" key={index}>
          <div className="col-span-1 flex rounded-md shadow-sm">
            <div className="flex w-16 flex-shrink-0 gap-2 items-center justify-center rounded-l-md text-sm font-medium border-b border-l border-t border-gray-200">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600"
                disabled
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 6a3 3 0 013-3h13.5a3 3 0 013 3v12a3 3 0 01-3 3H5.25a3 3 0 01-3-3V6zm18 3H3.75v9a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V9zm-15-3.75A.75.75 0 004.5 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H5.25zm1.5.75a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V6zm3-.75A.75.75 0 009 6v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H9.75z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="flex flex-1 flex-col justify-center items-start gap-1 py-2 truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="w-1/2 h-4 animate-pulse bg-gray-200 rounded-lg dark:bg-gray-700 " />
              <div className="w-3/4 h-4 animate-pulse bg-gray-200 rounded-lg dark:bg-gray-700 " />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
