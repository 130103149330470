import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  id?: string;
};

export const HeadingLevelTwoWithUnderline = ({ children, id }: Props) => {
  return (
    <div className="mt-6 border-b border-gray-200 pb-3">
      <h2
        className="text-base font-semibold leading-6 text-gray-900"
        id={id ?? null}
      >
        {children}
      </h2>
    </div>
  );
};
