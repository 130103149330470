import { JwtDecodeType } from '../type';

export const decodeJwtToken = (token: string): JwtDecodeType => {
  if (token === null || token === undefined) {
    return undefined;
  } else {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        ?.atob(base64)
        ?.split('')
        ?.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        ?.join(''),
    );

    return JSON.parse(jsonPayload);
  }
};
