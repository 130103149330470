import React from 'react';

export const Link = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_23547_42433)">
        <path
          d="M7.84339 5.07318C8.24328 5.26411 8.59152 5.54821 8.85884 5.90163C9.12616 6.25505 9.30477 6.66746 9.37965 7.10422C9.45453 7.54098 9.42351 7.98934 9.28918 8.41162C9.15485 8.8339 8.92115 9.21778 8.6077 9.53101L5.83847 12.3002C5.31914 12.8196 4.61477 13.1113 3.88032 13.1113C3.14588 13.1113 2.44151 12.8196 1.92218 12.3002C1.40284 11.7809 1.11108 11.0765 1.11108 10.3421C1.11108 9.60764 1.40284 8.90327 1.92218 8.38394L3.0034 7.30271M11.2188 6.91994L12.3 5.83872C12.8193 5.31938 13.1111 4.61502 13.1111 3.88057C13.1111 3.14612 12.8193 2.44175 12.3 1.92242C11.7807 1.40309 11.0763 1.11133 10.3418 1.11133C9.6074 1.11133 8.90303 1.40309 8.3837 1.92242L5.61447 4.69164C5.30102 5.00487 5.06731 5.38875 4.93299 5.81104C4.79866 6.23332 4.76764 6.68167 4.84252 7.11843C4.9174 7.55519 5.09601 7.96761 5.36333 8.32102C5.63065 8.67444 5.97889 8.95855 6.37878 9.14948"
          stroke="#043E8D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_23547_42433">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
