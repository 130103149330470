import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import { Button } from '../Button/Button';

import { Breadcrumbs, PageHeadingButtons } from '../../../utils/type';

type Props = {
  breadcrumbs: Breadcrumbs[];
  pageTitle: string;
  pageHeadingButtons?: PageHeadingButtons[];
};

export const PagingHeadings = ({
  breadcrumbs,
  pageTitle,
  pageHeadingButtons,
}: Props) => {
  return (
    <div>
      <div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            {breadcrumbs?.map((breadcrumb, bIndex) => (
              <li key={breadcrumb?.title}>
                <div className="flex">
                  {breadcrumb.href ? (
                    <Link
                      to={breadcrumb?.href}
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500 hover:text-gray-700`}
                    >
                      {breadcrumb?.title}
                    </Link>
                  ) : (
                    <p
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500`}
                    >
                      {breadcrumb.title}
                    </p>
                  )}
                  {bIndex < breadcrumbs?.length - 1 && (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {pageTitle}
          </h1>
        </div>
        <div className="mt-4 flex flex-shrink-0 gap-2 md:ml-4 md:mt-0">
          {pageHeadingButtons !== null &&
            pageHeadingButtons?.map((item, index) =>
              item?.href ? (
                <Link to={item?.href} key={index}>
                  <Button
                    type={item?.type}
                    className={item?.type}
                    onClick={item?.onClick}
                    disabled={item?.disabled}
                  >
                    {item?.title}
                  </Button>
                </Link>
              ) : (
                <Button
                  type={item?.type}
                  key={index}
                  variant={item?.variant}
                  onClick={item?.onClick}
                  disabled={item?.disabled}
                >
                  {item?.title}
                </Button>
              ),
            )}
        </div>
      </div>
    </div>
  );
};
