import React from 'react';

export const DetailArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 14"
      fill="none"
      className="ml-2 h-3.5 w-2.5"
    >
      <path
        d="M1.25 1L6.25 7L1.25 13"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
