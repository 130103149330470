import React, { ReactNode } from 'react';

type Props = { colSpan: number; children: ReactNode };

export const TableNoList = ({ colSpan, children }: Props) => {
  return (
    <tr>
      <td
        colSpan={colSpan}
        className="whitespace-nowrap px-3 py-4 text-center text-sm"
      >
        {children}
      </td>
    </tr>
  );
};
