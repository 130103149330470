import React from 'react';
import { Link } from '../SVG/Link';
import { TooltipTop } from './TooltipTop';
import { Transition } from '@headlessui/react';

type Props = {
  isCopied: boolean;
};
export const CopyLinkAndTooltip = ({ isCopied }: Props) => {
  return (
    <Transition
      show={isCopied}
      enter="transition ease-in-out duration-300"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-out duration-0"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1"
    >
      <div className="transition">
        <Link />
        <TooltipTop positionClass="absolute -right-[3.25rem] bottom-7 hidden w-max rounded-lg p-2.5 text-xs after:left-[50%] group-hover:block">
          Copy link to result
        </TooltipTop>
      </div>
    </Transition>
  );
};
