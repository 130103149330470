import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { PageHeadingButtons } from '../../../utils/type';
import { TooltipTop } from '../Tooltip/TooltipTop';
import clsx from 'clsx';

type Props = {
  breadcrumbs: { title: string; href?: string }[];
  pageTitle: string;
  pageHeadingButtons?: PageHeadingButtons[];
};

export const PagingHeadingsInAutosquare = ({
  breadcrumbs,
  pageTitle,
  pageHeadingButtons,
}: Props) => {
  const navigate = useNavigate();
  const [hoverIdx, setHoverIdx] = useState(null);
  return (
    <div>
      <div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            {breadcrumbs?.map((breadcrumb, bIndex) => (
              <li key={breadcrumb.title}>
                <div className="flex">
                  {breadcrumb.href ? (
                    <button
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500 hover:text-gray-700`}
                      onClick={() => navigate(breadcrumb.href)}
                    >
                      {breadcrumb.title}
                    </button>
                  ) : (
                    <p
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500`}
                    >
                      {breadcrumb.title}
                    </p>
                  )}
                  {bIndex < breadcrumbs?.length - 1 && (
                    <ChevronRightIcon
                      className="size-5 shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {pageTitle}
          </h2>
        </div>
        <div className="mt-4 flex shrink-0 md:ml-4 md:mt-0">
          {pageHeadingButtons?.map((item, index) => (
            <div key={index} className="relative">
              {hoverIdx === index && item.description && (
                <TooltipTop
                  positionClass={`icon-description ${index === 1 ? 'after:left-[65%]' : 'after:left-[50%]'}`}
                >
                  {item.description}
                </TooltipTop>
              )}
              <button
                type="button"
                className={clsx(
                  'ml-3 inline-flex items-center rounded-md px-3 py-2',
                  item.title
                    ? 'bg-congress-blue text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    : 'icon-button relative',
                )}
                onClick={item.title ? () => navigate(item.href) : item.onClick}
                onMouseEnter={() => setHoverIdx(index)}
                onMouseLeave={() => setHoverIdx(null)}
              >
                {item?.title ?? item?.icon}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
