import { useState } from 'react';

export const useLinkCopy = () => {
  const [isCopied, setIsCopied] = useState(false);

  const linkCopy = (url: string) => {
    window.navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2500);
  };

  return { isCopied, linkCopy };
};
