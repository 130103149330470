import React from 'react';

export const ArrowSpinner = () => {
  return (
    <div className="fixed left-0 top-0 z-[9999] flex size-full flex-1 items-center justify-center bg-gray-900/40 text-white">
      <button
        type="button"
        className="bg-congress-blue rounded-full p-1 text-sm font-semibold text-white shadow-sm"
        disabled
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-8 animate-spin"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
      </button>
    </div>
  );
};
