import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

type Props = {
  page: number;
  isDashboard?: boolean;
};

export const PaginationLeftButton = ({ page, isDashboard = true }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {isDashboard ? (
        <Link
          to={{
            pathname: location.pathname,
            search: `page=${page !== 1 ? page - 1 : 1}`,
          }}
          className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 ${
            page !== 1 ? "hover:bg-gray-50" : "cursor-default"
          }`}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      ) : (
        <button
          type="button"
          className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 ${
            page !== 1 ? "hover:bg-gray-50" : "cursor-default"
          }`}
          onClick={() =>
            navigate({
              pathname: location.pathname,
              search: `page=${page !== 1 ? page - 1 : 1}`,
            })
          }
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </>
  );
};
