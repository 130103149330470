import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  margin?: string;
  width?: string;
};

export const ErrorMessage = ({
  children,
  margin = "my-4",
  width = "w-full",
}: Props) => {
  return (
    <div
      className={`text-sm text-center text-error-message whitespace-pre-wrap ${width} ${margin}`}
    >
      {children}
    </div>
  );
};
