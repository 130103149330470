import React from 'react';

type Props = {
  number?: number;
  isLine?: boolean;
  outPadding?: string;
  isButtonSkeleton?: boolean;
};

export const DescriptionListSkeleton = ({
  number = 1,
  isLine = true,
  outPadding = 'p-4',
  isButtonSkeleton = false,
}: Props) => {
  return (
    <div
      role="status"
      className={`max-w-full animate-pulse rounded dark:divide-gray-700 ${
        isLine && 'divide-y divide-gray-200'
      }`}
    >
      {isButtonSkeleton && (
        <div className="flex animate-pulse items-center justify-end border border-solid border-gray-200 p-4">
          <div className="h-3.5 w-20 rounded-lg bg-gray-300 dark:bg-gray-700" />
        </div>
      )}
      {Array.from({ length: number }, (_, idx) => (
        <div
          className={`flex items-center justify-between ${outPadding}`}
          key={idx}
        >
          <div className="my-2 grid w-full grid-cols-3">
            <div className="h-3.5 w-1/2 rounded-full bg-gray-300 dark:bg-gray-700" />
            <div className="col-span-2 h-3.5 w-full rounded-full bg-gray-300 dark:bg-gray-700" />
          </div>
        </div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};
