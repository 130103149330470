/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FilteredList } from '../../../utils/type/repeatBox/type';

interface Props {
  value: {
    idx: number | null;
    label: string;
    disabled: boolean;
  };
  onChange: (...event: any[]) => void;
  filteredList: FilteredList[];
  placeholder?: string;
  isError?: boolean;
  error?: Error;
  disabledBox?: boolean;
  findIndex: number;
}

export const RepeatStepComboboxHeadlessUI = ({
  value,
  onChange,
  filteredList,
  isError,
  error,
  findIndex,
  placeholder = '스텝을 입력 및 선택해 주세요.',
  disabledBox = false,
}: Props) => {
  const [query, setQuery] = useState<string>('');

  const searchFilteredList = filteredList?.filter((item) =>
    item?.label.toLowerCase().includes(query ? query?.toLowerCase() : ''),
  );
  useEffect(() => {
    setQuery('');
  }, [value]);

  return (
    <Combobox as="div" value={value} onChange={onChange}>
      <div className="relative">
        <div className="flex w-full items-center justify-between gap-2">
          <div className="relative flex w-full items-center justify-between">
            <ComboboxInput
              className={clsx(
                'w-full rounded-md border-0  py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                filteredList?.length === 0 || disabledBox
                  ? 'bg-gray-50'
                  : 'bg-white',
              )}
              displayValue={(filteredValue: FilteredList) =>
                filteredValue?.label
              }
              onChange={(e) => setQuery(e.target.value)}
              placeholder={placeholder}
              disabled={filteredList?.length === 0 || disabledBox}
            />
            <ComboboxButton
              className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
              disabled={filteredList?.length === 0 || disabledBox}
            >
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
        </div>
        <ComboboxOptions
          className={`absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin focus:outline-none sm:text-sm`}
        >
          {isError ? (
            <div className="relative cursor-default select-none px-4 py-2 text-red-600">
              {error.message}
            </div>
          ) : searchFilteredList?.length === 0 ? (
            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
              Nothing found
            </div>
          ) : (
            searchFilteredList?.map((filteredValue) => (
              <ComboboxOption
                key={filteredValue.idx}
                className={({ focus }) =>
                  clsx(
                    'relative cursor-default select-none truncate whitespace-nowrap py-2 pl-3 pr-9',
                    focus
                      ? 'bg-indigo-600 text-white'
                      : filteredValue.disabled
                        ? 'text-gray-300'
                        : 'text-gray-900',
                  )
                }
                value={filteredValue}
                disabled={filteredValue.disabled}
              >
                {({ selected, focus }) => (
                  <>
                    <span
                      className={clsx('break-all', selected && 'font-semibold')}
                    >
                      {filteredValue.label}
                    </span>
                    {filteredValue.idx === findIndex && (
                      <span
                        className={clsx(
                          'absolute inset-y-0 right-0 flex items-center pr-4 ',
                          focus ? 'text-white' : 'text-indigo-600',
                        )}
                      >
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))
          )}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};
