import clsx from 'clsx';
import React from 'react';

type Props = {
  number: number;
  height?: string;
  outMargin?: string;
  width?: string;
  margin?: string;
};

export const DefaultSkeleton = ({
  number,
  height = 'h-4',
  outMargin = 'my-8',
  margin = 'my-4',
  width = '',
}: Props) => {
  return (
    <div className={`max-w-full animate-pulse ${outMargin}`}>
      {Array.from(Array(number), (_, idx) => (
        <div
          key={idx}
          className={clsx(
            'rounded-full bg-gray-200 dark:bg-gray-700',
            width,
            height,
            margin,
          )}
        />
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};
