import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isOverThreeSeconds?: boolean;
};

export const SpeedTestResultTableDataCell = ({
  children,
  isOverThreeSeconds = false,
}: Props) => {
  return (
    <td
      className={clsx(
        'px-3 py-2 text-sm',
        isOverThreeSeconds ? 'text-red-500' : 'text-gray-900',
      )}
    >
      {children}
    </td>
  );
};
