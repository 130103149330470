import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isFirstPaddingZero?: boolean;
  color?: string;
};

export const TableHeader = ({
  children,
  isFirstPaddingZero = false,
  color = 'text-gray-900',
}: Props) => {
  return (
    <th
      scope="col"
      className={clsx(
        'whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold',
        color,
        { 'first:pl-0': isFirstPaddingZero },
      )}
    >
      {children}
    </th>
  );
};
