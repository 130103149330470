import React from "react";

import { HeaderContainer } from "./HeaderContainer";
import { HeaderTitle } from "./HeaderTitle";
import { LocationFirstHeader } from "./LocationFirstHeader";
import { LocationHeader } from "./LocationHeader";
import { LocationHeaderList } from "./LocationHeaderList";

type Props = {
  firstHeader: string;
  headers?: string[];
  headerTitle: string;
};

export const MainHeader = ({ firstHeader, headers, headerTitle }: Props) => {
  return (
    <>
      <LocationHeader>
        <LocationFirstHeader>{firstHeader}</LocationFirstHeader>
        {headers?.map((headerList) => (
          <LocationHeaderList key={headerList}>{headerList}</LocationHeaderList>
        ))}
      </LocationHeader>
      <HeaderContainer>
        <HeaderTitle>{headerTitle}</HeaderTitle>
      </HeaderContainer>
    </>
  );
};
