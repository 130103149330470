import React, { ReactElement, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button/Button';
import { Breadcrumbs, PageHeadingButtons } from '../../../utils/type';
import { TooltipTop } from '../Tooltip/TooltipTop';
import { LinkButton } from '../Button/LinkButton';
import clsx from 'clsx';

type Props = {
  breadcrumbs?: Breadcrumbs[];
  pageTitle: string;
  searchBar?: ReactElement;
  pageHeadingButtons?: PageHeadingButtons[];
  url?: string;
  copy?: boolean;
};

export const PagingHeadingsNavigate = ({
  breadcrumbs,
  pageTitle,
  searchBar,
  pageHeadingButtons,
  url,
  copy = false,
}: Props) => {
  const navigate = useNavigate();
  const [hoverIdx, setHoverIdx] = useState(null);

  return (
    <div>
      {breadcrumbs && (
        <div>
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              {breadcrumbs.map((breadcrumb, bIndex) => (
                <li key={bIndex}>
                  <div className="flex">
                    {breadcrumb.href ? (
                      <button
                        type="button"
                        className={`text-sm font-medium text-gray-500 ${
                          bIndex < breadcrumbs.length - 1 && 'mr-4'
                        } ${!breadcrumb.disabled && 'hover:text-gray-700'}`}
                        onClick={() =>
                          navigate(breadcrumb.href, { state: breadcrumb.state })
                        }
                        disabled={breadcrumb.disabled}
                      >
                        {breadcrumb.title}
                      </button>
                    ) : (
                      <p
                        className={`${
                          bIndex < breadcrumbs.length - 1 && 'mr-4'
                        } text-sm font-medium text-gray-500`}
                      >
                        {breadcrumb.title}
                      </p>
                    )}

                    {bIndex < breadcrumbs.length - 1 && (
                      <ChevronRightIcon
                        className="size-5 shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      )}

      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div
          className={`min-w-0 ${copy && 'flex items-center justify-center gap-3'}`}
        >
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {pageTitle}
          </h2>
          {copy && <LinkButton url={url} />}
        </div>
        <div className="mt-4 flex shrink-0 gap-2 md:ml-4 md:mt-0">
          {searchBar}
          {pageHeadingButtons?.map((item, index) => (
            <div key={index} className="relative">
              {hoverIdx === index && item.description && (
                <TooltipTop
                  positionClass={clsx(
                    'icon-description after:left-1/2',
                    item.description.length > 12 &&
                      item.description.length <= 15 &&
                      '-right-9',
                    item.description.length > 15 &&
                      item.description.length <= 20 &&
                      '-right-11',
                    item.description.length > 20 && '-right-12',
                  )}
                >
                  {item.description}
                </TooltipTop>
              )}
              <Button
                type={item.type}
                variant={item.variant}
                onClick={item.onClick}
                disabled={item.disabled}
                onMouseEnter={() => setHoverIdx(index)}
                onMouseLeave={() => setHoverIdx(null)}
              >
                {item?.title ?? item?.icon}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
