import React from 'react';
import { SuccessCheck } from '../SVG/SuccessCheck';
import { TooltipTop } from './TooltipTop';
import { Transition } from '@headlessui/react';

type Props = {
  isCopied: boolean;
};

export const SuccessCheckAndTooltip = ({ isCopied }: Props) => {
  return (
    <Transition
      show={isCopied}
      enter="ease-in-out duration-500 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-2"
      enterTo="translate-y-0 opacity-100 sm:translate-y-0"
      leave="transition ease-in duration-0"
      leaveFrom="opacity-100 sm:translate-y-0"
      leaveTo="opacity-0 sm:translate-y-2"
    >
      <div className="transition">
        <TooltipTop
          positionClass={`absolute w-max text-xs px-4 py-2 rounded-lg -right-6 bottom-7 after:left-[50%]`}
        >
          Copied
        </TooltipTop>
        <SuccessCheck />
      </div>
    </Transition>
  );
};
