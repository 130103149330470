import React from "react";

type Props = {
  state?: string;
  labelName: string;
  inputName: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string | null;
  required?: boolean;
  isWrited?: boolean;
  placeholder?: string | null;
  checkValidation?: string | null;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
};

export const InputBasic = ({
  state,
  labelName,
  inputName,
  onChange,
  defaultValue,
  required,
  isWrited,
  placeholder,
  checkValidation,
  min,
  max,
  minLength,
  maxLength,
}: Props) => {
  return (
    <div className="w-full sm:col-span-3">
      <label
        htmlFor={labelName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {inputName}
        {isWrited && state?.length === 0 && (
          <span className="ml-2 text-error-message">
            필수 입력 사항 입니다.
          </span>
        )}
      </label>
      <div className="mt-2">
        <input
          type="text"
          name={labelName}
          id={labelName}
          placeholder={placeholder ?? null}
          className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={onChange}
          defaultValue={defaultValue ?? null}
          required={required ?? false}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
        />
        {checkValidation && (
          <p className="my-[0.5rem] text-center text-error-message">
            {checkValidation ?? null}
          </p>
        )}
      </div>
    </div>
  );
};
