export const validateEmail = (email: string) => {
  const regex =
    /^[0-9a-zA-Z]([-_.\]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.\]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regex.test(email);
};

export const validateUserName = (name: string) => {
  const regex = /^[ㄱ-힣a-zA-Z\s]+$/g;
  return regex.test(name);
};

export const startUserNameBlankCheck = (name: string) =>
  name[0] === ' ' ? true : false;

export const validatePassword = (password: string) => {
  const regex = /^(?=.*[~`!@#$%^&*()_=+-])(?=.*[a-zA-Z])(?=.*[0-9]).{8,16}$/g;
  return regex.test(password);
};

export const passwordTotalValidation = (password: string) =>
  password.length >= 8 && password.length <= 16 && validatePassword(password);

export const isInputValidLogin = (email: string, password: string) =>
  validateEmail(email) && passwordTotalValidation(password);

export const isInputValidSignUp = (
  name: string,
  password: string,
  repeat_password: string,
) =>
  validateUserName(name) &&
  !startUserNameBlankCheck(name) &&
  passwordTotalValidation(password) &&
  validatePassword(password) &&
  password === repeat_password;

export const validateCreateProjectName = (name: string) => {
  const regex = /^[a-z0-9-_.]{4,20}$/g;
  return regex.test(name);
};

export const regexLibrary = {
  ipAddressWithPortNumber:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]|6[0-4][0-9]{2}|[1-5][0-9]{0,4}|[0-9]{1,4})$/,
  ipAddress:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  url: /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})([/\w .-]*)*\/?$/,
  password: /^(?=.*[~`!@#$%^&*()_=+-])(?=.*[a-zA-Z])(?=.*[0-9]).{8,16}$/,
  userName: /^[ㄱ-힣a-zA-Z\s]+$/,
  sixDigitNumber: /^\d{6}$/,
  alphaNumericDashUnderscore: /^[a-zA-Z0-9-_]+$/,
  dateFormatRegex: /^(?:(?:YY|YYYY)([.\-/\s]?)|(?:MM|M)([.\-/\s]?)|(?:DD|D))+$/,
};
