import React, { ButtonHTMLAttributes } from 'react';
import { Download } from '../SVG/Download';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const DownloadButton = ({ ...attributes }: Props) => {
  return (
    <button
      className="mb-3 inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      {...attributes}
    >
      <Download />
    </button>
  );
};
