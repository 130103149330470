import React from 'react';

import { CheckBoxInput } from './CheckBoxInput';

type Props = {
  fieldsetName: string;
  inputField: {
    id: string;
    labelName?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    checked?: boolean;
    defaultChecked?: boolean;
  }[];
};

export const Fieldset = ({ fieldsetName, inputField }: Props) => {
  return (
    <fieldset>
      <legend className="sr-only">{fieldsetName}</legend>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
        <div
          className="text-sm font-semibold leading-6 text-gray-900"
          aria-hidden="true"
        >
          {fieldsetName}
        </div>
        <div className="mt-4 sm:col-span-2 sm:mt-0">
          <div className="flex max-w-lg space-x-6">
            {inputField?.map((field) => (
              <CheckBoxInput
                key={field.id}
                id={field.id}
                labelName={field.labelName}
                onChange={field.onChange}
                checked={field.checked}
                defaultChecked={field.defaultChecked}
                disabled={field.disabled}
              />
            ))}
          </div>
        </div>
      </div>
    </fieldset>
  );
};
